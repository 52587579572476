import { $api } from "@/services/api.service";

function getCookie( iname )
{
    const name = iname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for( let i = 0; i <ca.length; i++ )
    {
        let c = ca[i];

        while (c.charAt(0) === ' ')
        {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0)
        {
            return c.substring( name.length, c.length );
        }
    }

    return "";
}

function setCookie( name, value, expire )
{
    const d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000));

    expire = ( typeof expire != 'undefined' ? expire : d.toUTCString() );

    document.cookie = name + '=' + value + '; expires=' + expire + '; sameSite=Strict; Secure; domain='+( process.env.NODE_ENV === 'development' ? '.sandbox' : '' )+'.ramp.global; path=/;';
}

class AuthService
{
    isCookieSet()
    {
        return Boolean( getCookie('analytics_access_token') );
    }

    getPermissions()
    {
        const permissions = [];

        if( getCookie('analytics_access_token').includes('-management') || process.env.NODE_ENV !== 'production' )
        {
            permissions.push( 'analytics:management:read' );
            permissions.push( 'analytics:goal:read' );
            permissions.push( 'analytics:goal:edit' );
            permissions.push( 'analytics:performance:read' )
        }

        if( getCookie('analytics_access_token').includes('-documents') || process.env.NODE_ENV !== 'production' )
        {
            permissions.push( 'analytics:documents:read' );
        }

        return permissions;
    }

    async getUser()
    {
        let detail = await $api['app'].get( '/me' ).catch( e =>
        {
            console.log( 'AuthService::login', e );
            return false;
        } );
        return detail;//{ firstName: 'tomas', lastName : 'Aloha' };
    }

    async logout()
    {
        //setCookie('analytics_access_token', '', -1 );
        //return true;

        let detail = await $api['iam'].get( '/auth/logout' ).catch( e =>
        {
            console.log( 'AuthService::login', e );
            return false;
        } );
        return true;
    }
}

export default new AuthService();