import permissions from '@/config/permissions'; //Alias for roles
import store from "@/store";

/*
    add alias to file -> permision : [ analytics:management:read ]
    alias example -> @permision
 */

const hasPermissions = ( rules ) =>
{
    if( Array.isArray( rules ) )
    {
        let isTrue = true;

        for( const rule of rules)
        {
            if( !hasPermission( rule ) )
            {
                isTrue = false;
            }
        }

        return isTrue;
    }
    else
    {
        return hasPermission( rules );
    }
}

const hasPermission = ( rule ) =>
{
    const userRoles = store.getters['auth/userRoles'];
    if( rule && rule.indexOf('@') === 0 )
    {
        let isTrue = true;
        const aliasRule = rule.substr(1);
        if( Array.isArray( permissions[ aliasRule ] )  )
        {
            for( const permission of permissions[ aliasRule ] )
            {
                if( !userRoles.includes( permission ) )
                {
                    isTrue = false;
                }
            }
        }
        else
        {
            if( !userRoles.includes( permissions[ aliasRule ] ) )
            {
                isTrue = false;
            }
        }

        return isTrue;
    }
    else if( rule )
    {
       return userRoles.includes( rule )
    }

    return false;
}


const hasPermissionsPlugin =
{
    install: ( app, options ) =>
    {
        app.config.globalProperties.$hasPermission = hasPermissions;
    }
}

const hasPermissionsDirective =
{
    mounted( el, bindings, vnode )
    {
        if( bindings.value )
        {
            if( !hasPermissions( bindings.value ) )
            {
                commentNode(el, vnode);
            }
        }
    },
    updated( el, bindings, vnode )
    {
        if( bindings.value )
        {
            if( !hasPermissions( bindings.value ) )
            {
                commentNode(el, vnode);
            }
        }
    }
}

export { hasPermissionsDirective, hasPermissionsPlugin, hasPermissions };

function commentNode( el, vnode )
{
    const comment = document.createComment('v-hasPermissions');

    Object.defineProperty(comment, 'setAttribute',
    {
        value: () => undefined
    } );

    vnode.text = ' ';
    vnode.elm = comment;
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;

    if( vnode?.data?.directives )
    {
        vnode.data.directives = undefined;
    }

    if ( vnode.componentInstance )
    {
        vnode.componentInstance.$el = comment;
    }

    if (el.parentNode)
    {
        el.parentNode.replaceChild(comment, el);
    }
}