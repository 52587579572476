export default
{
    install: ( app ) =>
    {
        const Dialogs = {};

        app.config.globalProperties.$dialogs = Dialogs;

        app.provide( 'dialogs', Dialogs )
    }
}