<template>
	<div ref="loadingRef" class="Loading-screen">
		<div class="lds-heart"><div></div></div>
	</div>
</template>

<script setup>
	import { ref } from 'vue';

	const loadingRef = ref( null );

	const hidden = () =>
	{
		setTimeout( () =>
		{
			loadingRef.value?.classList.add('hidden');
			setTimeout( () =>
			{
				loadingRef.value?.remove()
			}, 400 );
		}, 500 );
	}

	defineExpose( { hidden } )
</script>

<style lang="scss">
	.Loading-screen
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 1;
		background-color: #fff;
		transition: opacity 250ms;
		z-index: 9999;

		&.hidden
		{
			opacity: 0;
		}

		.lds-ripple
		{
			display: inline-block;
			position: relative;
			width: 80px;
			height: 80px;

			div
			{
				position: absolute;
				border: 0px solid #000;
				opacity: 1;
				border-radius: 50%;
				background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m173.92062,58.09667l-84.0709,31.75317l0,23.93963l-73.48611,27.75542c16.26225,23.07577 43.11013,38.15475 73.48611,38.15475c49.62276,0 89.84988,-40.22716 89.84988,-89.8498c0,-11.17978 -2.04784,-21.87975 -5.77898,-31.75317z'/%3E%3Cpath d='m89.84972,71.49619l0,-5.58752l73.48569,-27.75471c-16.26217,-23.07531 -43.10988,-38.15396 -73.48569,-38.15396c-49.62247,0 -89.84972,40.22712 -89.84972,89.8498c0,11.17962 2.04768,21.87946 5.77881,31.75271l84.0709,-31.75271l0,-18.35361z'/%3E%3C/svg%3E");
				background-position: center;
				background-size: cover;

				animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

				&:nth-child(2)
				{
					animation-delay: -0.5s;
				}
			}
		}
	}

	.lds-heart
	{
		display: inline-block;
		position: relative;
		width: px(80);
		height: px(80);

		div
		{
			width: px(80);
			height: px(80);
			background: #fff;
			animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
			background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m173.92062,58.09667l-84.0709,31.75317l0,23.93963l-73.48611,27.75542c16.26225,23.07577 43.11013,38.15475 73.48611,38.15475c49.62276,0 89.84988,-40.22716 89.84988,-89.8498c0,-11.17978 -2.04784,-21.87975 -5.77898,-31.75317z'/%3E%3Cpath d='m89.84972,71.49619l0,-5.58752l73.48569,-27.75471c-16.26217,-23.07531 -43.10988,-38.15396 -73.48569,-38.15396c-49.62247,0 -89.84972,40.22712 -89.84972,89.8498c0,11.17962 2.04768,21.87946 5.77881,31.75271l84.0709,-31.75271l0,-18.35361z'/%3E%3C/svg%3E");
			background-position: center;
			background-size: cover;
		}
	}

	@keyframes lds-ripple {
		0% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 0;
		}
		4.9% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 0;
		}
		5% {
			top: 36px;
			left: 36px;
			width: 0;
			height: 0;
			opacity: 1;
		}
		100% {
			top: 0px;
			left: 0px;
			width: 72px;
			height: 72px;
			opacity: 0;
		}
	}

	@keyframes lds-heart {
		0% {
			transform: scale(0.95);
		}
		5% {
			transform: scale(1.1);
		}
		39% {
			transform: scale(0.85);
		}
		45% {
			transform: scale(1);
		}
		60% {
			transform: scale(0.95);
		}
		100% {
			transform: scale(0.9);
		}
	}
</style>