import { createRouter, createWebHistory } from 'vue-router';
import store from "@/store";

const routes =
[
    {
        path      : '/',
        name      : 'Root',
        component : () => import(/* webpackChunkName: "Root" */'@/templates/layouts/Admin'),
        redirect  : 'overview',
        children  :
        [
            {
                path        : '/overview-v2',
                name        : 'WeeklyOverviewV2',
                component   : () => import(/* webpackChunkName: "WeeklyOverview2.0" */'@/templates/views/admin/OverviewV2'),
                props       : (route) => { return { version: 2 } },
                meta        : { requiresAuth : true, navigation: false, title: 'Weekly Overview', icon: 'fa-chart-area' },
            },
            {
                path        : '/overview',
                name        : 'WeeklyOverviewV3',
                component   : () => import(/* webpackChunkName: "WeeklyOverview3.0" */'@/templates/views/admin/OverviewV2'),
                props       : (route) => { return { version: 3 } },
                meta        : { requiresAuth : true, navigation: true, title: 'Weekly Overview', icon: 'fa-chart-area' },
            },
            {
                path        : '/overview-v1',
                name        : 'WeeklyOverviewV1',
                component   : () => import(/* webpackChunkName: "Overview" */'@/templates/views/admin/OverviewV2'),
                props       : (route) => { return { version: 1 } },
                meta        : { permissions: ['analytics:management:read'], requiresAuth : true, navigation: false, title: 'Weekly Overview v1', icon: 'fa-chart-line' },
            },
            {
                path        : '/management-overview',
                name        : 'ManagementOverview',
                component   : () => import(/* webpackChunkName: "Management" */'@/templates/views/admin/Management'),
                meta        : { permissions: ['analytics:management:read'], requiresAuth : true, navigation: true, title: 'Management Overview', icon: 'fa-chart-area' },
            },
            /*{
                path        : '/performance',
                name        : 'Performance',
                component   : () => import(/* webpackChunkName: "Performance" * /'@/templates/views/admin/Performance'),
                meta        : { permissions: ['analytics:performance:read'], requiresAuth : true, navigation: true, title: 'Performance Overview', icon: 'fa-chart-line' },
            },*/
            {
                path        : '/goalV1',
                name        : 'GoalV1',
                component   : () => import(/* webpackChunkName: "Goal" */'@/templates/views/admin/Goal'),
                meta        : { permissions: ['analytics:goal:read'], requiresAuth : true, navigation: false, title: 'Goal', icon: 'fa-trophy' },
            },
            {
                path        : '/goal',
                name        : 'Goal',
                component   : () => import(/* webpackChunkName: "Goal V2" */'@/templates/views/admin/GoalV2'),
                meta        : { permissions: ['analytics:goal:read'], requiresAuth : true, navigation: true, title: 'Goal', icon: 'fa-trophy' },
            },
            {
                path        : '/agency-suggestor',
                name        : 'AgencySuggestor',
                component   : () => import(/* webpackChunkName: "AgencySuggestor" */'@/templates/views/admin/AgencySuggestor'),
                meta        : { requiresAuth : true, navigation: true, title: 'Agency Suggestor', icon: 'fa-comment-dots' },
            },
            {
                path        : '/trackingElapsedTime',
                name        : 'TrackingElapsedTime',
                component   : () => import(/* webpackChunkName: "TrackingElapsedTime" */'@/templates/views/admin/TrackingElapsedTime'),
                meta        : { requiresAuth : true, navigation: false, title: 'Tracking Elapsed Time', icon: 'fa-briefcase' },
            },
            {
                path        : '/tv',
                name        : 'TVDashboard',
                meta        : { requiresAuth : true, navigation: true, title: 'TV Dashboard', icon: 'fa-television' },
                redirect    : '/presentation/overview',
            },
            {
                path        : '/calls',
                name        : 'Calls',
                component   : () => import(/* webpackChunkName: "Calls" */'@/templates/views/admin/Calls'),
                meta        : { requiresAuth : true, navigation: true, title: 'Calls', icon: 'fa-phone-rotary' },
            },
            {
                path        : '/contacts',
                name        : 'Contacts',
                component   : () => import(/* webpackChunkName: "Contacts" */'@/templates/views/admin/Contacts'),
                meta        : { requiresAuth : true, navigation: true, title: 'Contacts', icon: 'fa-address-book' },
            },
            {
                path        : '/documents',
                name        : 'Documents',
                component   : () => import(/* webpackChunkName: "Documents" */'@/templates/views/admin/Documents'),
                meta        : { permissions: ['analytics:documents:read'], requiresAuth : true, navigation: true, title: 'Documents', icon: 'fa-folder-open' },
                children  :
                [
                    {
                        path        : '/documents/:list?/:type?/:id?',
                        name        : 'DocumentsList',
                        component   : () => import(/* webpackChunkName: "Documents" */'@/templates/views/admin/Documents'),
                        beforeEnter : async ( to, from ) =>
                        {
                            if( to.params.list != '' && !['list','search'].includes( to.params.list ) )
                            {
                                return { path: '/documents' }
                            }
                            else if( to.params.type != '' && !['supplier','contractor'].includes( to.params.type ) )
                            {
                                return { path: '/documents' }
                            }
                        },
                        meta        : { permissions: ['analytics:documents:read'], requiresAuth : true, navigation: false, title: 'Documents', icon: 'fa-folder-open' },
                    }
                ]
            },
            {
                path        : '/tracking',
                name        : 'PresentationTracking',
                meta        : { requiresAuth : true, navigation: true, title: 'Employer breakdown', icon: 'fa-chart-tree-map' },
                component   : () => import(/* webpackChunkName: "PresentationOverview" */'@/templates/views/admin/Tracking.vue'),
            },
            {
                path        : '/employer',
                name        : 'Employer',
                component   : () => import(/* webpackChunkName: "Employer" */'@/templates/views/admin/Employer'),
                meta        : { requiresAuth : true, navigation: false, title: 'Employee overview', icon: 'fa-chart-pyramid' },
            },
            {
                path        : '/employer-performance',
                name        : 'EmployerPerformance',
                component   : () => import(/* webpackChunkName: "Employer Performance" */'@/templates/views/admin/EmployerPerformance'),
                meta        : { permissions: ['analytics:management:read'], requiresAuth : true, navigation: true, title: 'Employer performance', icon: 'fa-chart-line' },
            }
        ]
    },
    {
        path      : '/playground',
        name      : 'Playground',
        component : () => import(/* webpackChunkName: "Playground" */'@/templates/layouts/Full'),
        redirect  : 'playground/canvas',
        children  :
        [
            {
                path        : 'canvas',
                name        : 'CanvasPlayground',
                meta        : { requiresAuth : true },
                component   : () => import(/* webpackChunkName: "CanvasPlayground" */'@/templates/views/playground/Canvas.vue'),
            },
            {
                path        : 'funnel',
                name        : 'FunnelPlayground',
                component   : () => import(/* webpackChunkName: "FunnelPlayground" */'@/templates/views/playground/Funnel.vue'),
                meta        : { requiresAuth : true, title: 'Funnel Playground', icon: 'fa-briefcase' },
            },
            {
                path        : 'query-builder',
                name        : 'QueryBuilderPlayground',
                component   : () => import(/* webpackChunkName: "QueryBuilderPlayground" */'@/templates/views/playground/QueryBuilder.vue'),
                meta        : { requiresAuth : true, title: 'Query Builder Playground', icon: 'fa-briefcase' },
            },
            {
                path        : 'query-builder/:queryID',
                name        : 'QueryDetail',
                meta        : { requiresAuth: true },
                props       : ( route ) => { return { ...route.params, queryID: route.params.queryID } },
                component   : () => import('@/templates/views/playground/QueryBuilder.vue')
            },
            {
                path        : 'chart-builder',
                name        : 'ChartBuilderPlayground',
                component   : () => import(/* webpackChunkName: "ChartBuilderPlayground" */'@/templates/views/playground/ChartBuilder.vue'),
                meta        : { requiresAuth : true, title: 'Chart Builder Playground', icon: 'fa-briefcase' },
            },
            {
                path        : 'overview-builder',
                name        : 'OverviewBuilderPlayground',
                component   : () => import(/* webpackChunkName: "OverviewBuilderPlayground" */'@/templates/views/playground/OverviewBuilder.vue'),
                meta        : { requiresAuth : true, title: 'Overview Builder Playground', icon: 'fa-briefcase' },
            }
        ]
    },
    {
        path      : '/tools',
        name      : 'Tools',
        component : () => import(/* webpackChunkName: "Tools" */'@/templates/layouts/Full'),
        redirect  : 'tools/jobs',
        children  :
        [

            {
                path        : 'jobs',
                name        : 'JobsTools',
                component   : () => import(/* webpackChunkName: "JobsTools" */'@/templates/views/tools/Jobs.vue'),
                meta        : { requiresAuth : true, title: 'Jobs Playground', icon: 'fa-briefcase' },
            },
            {
                path        : 'breakdown',
                name        : 'BreakdownTools',
                component   : () => import(/* webpackChunkName: "BreakdownTools" */'@/templates/views/tools/Breakdown.vue'),
                meta        : { requiresAuth : true, title: 'Breakdown Tools', icon: 'fa-briefcase' },
            }
        ]
    },
    {
        path      : '/presentation',
        name      : 'Presentation',
        component : () => import(/* webpackChunkName: "Presentation" */'@/templates/layouts/Full'),
        redirect  : 'presentation/overview',
        children  :
        [
            {
                path        : 'overview',
                name        : 'PresentationOverview',
                meta        : { requiresAuth : true },
                component   : () => import(/* webpackChunkName: "PresentationOverview" */'@/templates/views/presentation/Overview'),
            }
        ]
    },
    {
        path        : '/logout',
        name        : 'Logout',
        meta        : { requiresAuth : false },
        beforeEnter : async ( to, from ) =>
        {
            await store.dispatch('auth/logout');
        }
    },
    {
        path        : '/access-denied',
        name        : 'AccessDenied',
        meta        : { requiresAuth : false },
        component   : () => import(/* webpackChunkName: "404" */'@/templates/views/system/AccessDenied')
    },
    {
        path        : '/:pathMatch(.*)*',
        name        : '404',
        meta        : { requiresAuth : false },
        component   : () => import(/* webpackChunkName: "404" */'@/templates/views/system/NotFound')
    }
]

const router = createRouter({ history: createWebHistory(), routes });

export default router;