export default function( store )
{
    try
    {
        // TODO
    }
    catch( error )
    {
        console.error('WebSocket plugin error =>', error);
    }
}