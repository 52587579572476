import { $api } from "@/services/api.service";

export default function( store )
{
    try
    {
        store.$api = $api;
    }
    catch( error )
    {
        console.error('Api plugin error =>', error);
    }
}