import { createApp }  from 'vue'

import kit            from '@ramp-global/vue-kit';

import Floating       from "floating-vue";
import                     "floating-vue/dist/style.css"
import Toast, { TYPE } from "vue-toastification";
import                      "vue-toastification/dist/index.css";

import App            from './templates/App.vue'
import router         from './router'
import store          from './store'

import api            from './plugins/api';
import i18n           from './plugins/i18n';
import dialog         from './plugins/dialog';
//import dragabble      from './plugins/dragabble';
import { hasPermissionsDirective, hasPermissionsPlugin, hasPermissions } from './plugins/permissions';

const rampApp = createApp( App );

const toastOptions =
    {
        transition: 'Vue-Toastification__bounce',
        maxToasts: 5,
        newestOnTop: true,
        position: 'bottom-right',
        toastDefaults:
            {
                [TYPE.SUCCESS]  : { timeout: 3000, showCloseButtonOnHover: true, hideProgressBar: true },
                [TYPE.ERROR]    : { timeout: false, showCloseButtonOnHover: true, hideProgressBar: true },
                [TYPE.WARNING]  : { timeout: 5000, showCloseButtonOnHover: true },
                [TYPE.INFO]     : { timeout: 5000, showCloseButtonOnHover: true },
                [TYPE.DEFAULT]  : { timeout: 5000, showCloseButtonOnHover: true }
            }
    }


router.beforeEach( async (to, from ) =>
{
    /*
    if( !store.getters['auth/user'] )
    {
        await store.dispatch( 'auth/getUser', { requiresAuth : to.meta.requiresAuth } );
    }
    */

    if( store.getters['auth/isLogged'] && to.meta.requiresAuth && to.meta.permissions && !hasPermissions( to.meta.permissions ) )
    {
        return { path: '/access-denied' };
    }

    if( !store.getters['auth/isLogged'] && to.meta.requiresAuth )
    {
        return { path: '/logout' };
    }
});

rampApp.use( store )
    .use( api )
    .use( Floating, { themes: { 'input-hint-tooltip': { $extend: 'tooltip' } } } )
    .use(Toast, toastOptions )
    .use( i18n, { hello: 'ahoj' } )
    .use( dialog )
    //.use( dragabble )
    .use( router )
    .use( kit )
    .use( hasPermissionsPlugin )
    .directive( 'hasPermission', hasPermissionsDirective )
    .mount( 'body' );