import { createStore }  from 'vuex';

import ramp             from "./modules/ramp.module";
import auth             from "./modules/auth.module";

import ApiPlugin        from "./plugins/api.plugin";
import webSocketPlugin  from "./plugins/websocket.plugin";

export default createStore(
{
    modules: { auth, ramp },
    plugins: [ ApiPlugin, webSocketPlugin ]
})
